import React from 'react';

const UniqueDesign = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="vuesax_outline_designtools" data-name="vuesax/outline/designtools" transform="translate(-684 -188)">
        <g id="designtools">
          <path id="Vector" d="M4.91,17.124a.718.718,0,0,1-.42-.13.738.738,0,0,1-.33-.62,3.829,3.829,0,0,0-.03-.47,3.155,3.155,0,0,0-.95-1.87,3.311,3.311,0,0,0-1.93-.97,3.224,3.224,0,0,0-.45-.01.718.718,0,0,1-.65-.3.781.781,0,0,1-.11-.71,3.49,3.49,0,0,1,.63-1.08l1.54-1.94A34.709,34.709,0,0,1,14.16.154a2.169,2.169,0,0,1,2.82,2.82,34.588,34.588,0,0,1-8.86,11.95L6.15,16.5a4.388,4.388,0,0,1-.97.56A.626.626,0,0,1,4.91,17.124Zm-2.89-5.43a4.863,4.863,0,0,1,2.22,1.29,4.619,4.619,0,0,1,1.26,2.14l1.69-1.36a32.643,32.643,0,0,0,8.4-11.34.628.628,0,0,0-.16-.72.669.669,0,0,0-.73-.14A33.04,33.04,0,0,0,3.37,9.974Z" transform="translate(689.52 189.246)" fill="CurrentColor" />
          <path id="Vector-2" data-name="Vector" d="M2.849,9.964a2.867,2.867,0,0,1-2.83-3.2L.289,4.3A4.8,4.8,0,0,1,5.029,0a5.2,5.2,0,0,1,.67.02,4.846,4.846,0,0,1,2.84,1.41,4.676,4.676,0,0,1,1.37,2.72,5.162,5.162,0,0,1,.05.67,4.828,4.828,0,0,1-4.31,4.85l-2.47.27C3.069,9.954,2.959,9.964,2.849,9.964Zm2.37-8.45h-.13a3.32,3.32,0,0,0-3.31,2.96l-.27,2.46a1.389,1.389,0,0,0,.39,1.13,1.347,1.347,0,0,0,1.11.39l2.46-.27a3.346,3.346,0,0,0,2.98-3.36,3.829,3.829,0,0,0-.03-.47,3.155,3.155,0,0,0-.95-1.87,3.311,3.311,0,0,0-1.93-.97Z" transform="translate(685.231 200.786)" fill="CurrentColor" />
          <path id="Vector-3" data-name="Vector" d="M5.48,6.23a.755.755,0,0,1-.75-.75A3.984,3.984,0,0,0,.75,1.5.755.755,0,0,1,0,.75.747.747,0,0,1,.74,0,5.488,5.488,0,0,1,6.22,5.48.734.734,0,0,1,5.48,6.23Z" transform="translate(692.76 196.99)" fill="CurrentColor" />
          <path id="Vector-4" data-name="Vector" d="M2.967,10.746a3.927,3.927,0,0,1-2.75-1.33.75.75,0,0,1,1.06-1.06c1.18,1.18,2.19,1.18,3.37,0L7.607,5.4c1.19-1.19,1.19-2.2,0-3.38l-.74-.73a.755.755,0,0,1-.01-1.06.745.745,0,0,1,1.06-.01l.74.73c1.78,1.78,1.78,3.73,0,5.51L5.7,9.416A3.891,3.891,0,0,1,2.967,10.746Z" transform="translate(696.713 199.984)" fill="CurrentColor" />
          <path id="Vector-5" data-name="Vector" d="M1.85,10a.725.725,0,0,1-.53-.22,3.525,3.525,0,0,1,0-5.5L4.28,1.32a3.525,3.525,0,0,1,5.5,0l.74.74A.75.75,0,1,1,9.46,3.12l-.75-.74c-1.18-1.18-2.2-1.18-3.37,0L2.38,5.34c-1.18,1.19-1.18,2.2,0,3.39a.754.754,0,0,1,0,1.06A.775.775,0,0,1,1.85,10Z" transform="translate(685.26 189.26)" fill="CurrentColor" />
          <path id="Vector-6" data-name="Vector" d="M.748,5.2a.742.742,0,0,1-.53-.22.754.754,0,0,1,0-1.06l3.7-3.7a.75.75,0,0,1,1.06,1.06l-3.7,3.7A.742.742,0,0,1,.748,5.2Z" transform="translate(690.802 191.102)" fill="CurrentColor" />
          <path id="Vector-7" data-name="Vector" d="M.748,4.458a.742.742,0,0,1-.53-.22.754.754,0,0,1,0-1.06L3.178.218a.75.75,0,1,1,1.06,1.06l-2.96,2.96A.71.71,0,0,1,.748,4.458Z" transform="translate(700.412 199.973)" fill="CurrentColor" />
          <path id="Vector-8" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(684 188)" fill="none" opacity="0" />
        </g>
      </g>
    </svg>

  );
};

export default UniqueDesign;
